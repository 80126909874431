import React, { useEffect, useState } from "react";
import AudioRecorder from "../../../../components/AudioRecorder";
import { Button, UncontrolledPopover, PopoverBody } from "reactstrap";
interface EndButtonsProps {
  onSubmit: () => void;
  disabled: boolean;
  onSelectAudio: (audio: File) => void;

}
const EndButtons = ({ onSubmit, disabled, onSelectAudio }: EndButtonsProps) => {
  
  const [popoveropen, setPopoverOpen] = useState(false);

  const togglePopover = () => setPopoverOpen(!popoveropen);

  const [recorderLoaded, setRecorderLoaded] = useState<boolean>(false);
  
  const onStopRecorder = () => {
    console.log('==== stop recording ===');
    setRecorderLoaded(false);
    // release();
  }; 
  // const onLoadRecorder = () => {
  //   // console.log('===========  Load recording ==========', audio);
  //   setRecorderLoaded(true);
  // };  

  return (
    <div className="chat-input-links ms-2 gap-md-1">
      <div className="links-list-item d-none d-sm-block">
      

{/*
        <Button
          color="none"
          type="button"
          className="btn btn-link text-decoration-none btn-lg waves-effect"
          id="audio-btn"
        >
          <i className="bx bx-microphone align-middle"></i>
        </Button>

*/}

{/*        <Button
          color="none"
          type="button"
          className="btn btn-link text-decoration-none btn-lg waves-effect"
          id="audio-btn"
          onClick={togglePopover}
        >
          <i className="bx bx-microphone align-middle"></i>
        </Button>

*/}

      </div>
{/*      
      <UncontrolledPopover trigger="focus" placement="top" target="audio-btn">
        <PopoverBody>
          <div className="loader-line">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </PopoverBody>
      </UncontrolledPopover>

*/}




      <div className="links-list-item">
        { recorderLoaded ? (
            <AudioRecorder onSelectAudio={onSelectAudio} onStopRecorder ={onStopRecorder} />  
          ) : (
            <button
              type="button"
              color="none"
              className="btn btn-link text-decoration-none btn-lg waves-effect"
              id="audio-btn"
              onClick={() => {
                setRecorderLoaded(true);
              }}
            >
              <i className="bx bx-microphone align-middle"></i> // Boxicons Play Icon   
            </button>   
          )}
        
        

{/*
        <UncontrolledPopover trigger="focus" placement="top" target="audio-btn" isOpen={popoveropen}>
          <PopoverBody>
            <div className="loader-line">
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </PopoverBody>
        </UncontrolledPopover>
            
*/}

        <Button
          color="primary"
          type="submit"
          disabled={disabled}
          className="btn btn-primary btn-lg chat-send waves-effect waves-light"
        >
          <i className="bx bxs-send align-middle"></i>
        </Button>
      </div>
    </div>
  );
};

export default EndButtons;
